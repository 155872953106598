import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {ManualPage} from './manual.page';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentModule} from '../../components/component.module';

const routes: Routes = [
    {
        path: '',
        component: ManualPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ComponentModule,
        TranslateModule,
    ],
    declarations: [ManualPage]
})
export class ManualPageModule {
}
