import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseHttpService} from '../base-http.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService extends BaseHttpService {

    constructor(
        private http: HttpClient,
    ) {
        super();
    }

    documentInfo(id) {
        return this.http.get<any>(`${this.baseUrl}/documents/info/${id}` );
    }

    searchDocuments(search: string) {
        return this.http.get<any>(`${this.baseUrl}/documents/search?search=${search}`);
    }

    fetchDocuments() {
        return this.http.get<any>(`${this.baseUrl}/documents`);
    }

    fetchDocumentsToApprove() {
        return this.http.get<any>(`${this.baseUrl}/documents/to-approve`);
    }

    fetchSpecificFolder(id) {
        return this.http.get<any>(`${this.baseUrl}/documents/${id}` );
    }

    approveSpecificDocument(dossierId: number) {
        return this.http.post(`${this.baseUrl}/documents/show/${dossierId}/approve`, {});
    }
}
