<div class="overlay" (click)="close()" [class.show]="!!active"></div>

<div class="menu-slider"  [class.active]="!!active" (click)="closeOnContentClick()" [class.without-max-height]="freeHeight">
    <div *ngIf="title" class="menu-slider-title">
      {{ title }}
    </div>

    <div class="menu-slider-content" [class.menu-slider-content--without-x-padding]="withoutPadding" [class.menu-slider-content--without-y-padding]="withoutYPadding">
        <h3 *ngIf="subTitle">{{ subTitle }}</h3>

        <ng-content></ng-content>
    </div>
</div>
