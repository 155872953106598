<div class="container">
    <div class="modal-wrapper">
        <h1>{{ 'accoding-documents.title' | translate }}</h1>
        <span>{{ 'accoding-documents.message' | translate }}</span>
        <div class="confirm-btn-wrapper">
            <ion-button (click)="approve()">
                {{ 'accoding-documents.button-content' | translate }}
            </ion-button>
        </div>
    </div>
</div>
