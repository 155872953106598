<ion-header [class.with-background]="coloredToolbar" no-border>
    <ion-toolbar [style.background]="toolbarOpacity">
        <ion-buttons slot="start">
            <ion-back-button (click)="goBack()" defaultHref="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title *ngIf="showToolbarTitle">
            {{ 'manual-list.title' | translate }}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="showSearchBox()" [style.color]="ionButton">
                <ion-icon class="icon-search"></ion-icon>
            </ion-button>
            <ion-button (click)="chapterListActive = ! chapterListActive" [style.color]="ionButton">
                <ion-icon class="icon-more"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content (ionScroll)="onScroll($event)" *ngIf="isDataLoaded" [scrollEvents]="true" fullscreen>
    <div class="wrapper-div manual-wrapper">
        <div class="left">
            <div class="header-container">
                <img class="header" src="assets/images/instructions-header.jpg" />
                <div class="overlay"></div>
            </div>

            <div class="content-wrap">
                <h2 class="instructions-title">{{ manual.name }}</h2>
                <p class="instructions-version">{{ 'manual.version' | translate }} {{ manual.version }}</p>
            </div>

            <div *ngIf="hasNewVersion()" class="content-wrap">
                <div class="alert-section">
                    <p>{{'manual-changes.changes-label' | translate : { "date": getPublishDate } }}</p>
                    <ion-button (click)="gotoChanges()"
                                color="light">{{ 'manual-changes.show-adjustments' | translate }}</ion-button>
                </div>
            </div>

            <div *ngFor="let chapter of getChapters(); let chapterIndex = index" class="chapter-wrapper"
                 id="chapter_{{ chapter.id }}">
                <div class="content-wrap">
                    <h1 [id]="chapter.translations[0].content" [attr.data-chapter-id]="chapter.id">
                        {{ chapterIndex + 1}}. {{ chapter.translations[0].content }}
                    </h1>
                </div>

                <ab-component *ngFor="let component of chapter.components" [component]="component"></ab-component>

                <div *ngFor="let paragraph of getParagraphs(chapter); let paragraphIndex = index"
                     class="paragraph-wrapper" id="chapter_{{ paragraph.id }}">
                    <div class="content-wrap">
                        <h1 [id]="paragraph.translations[0].content" [attr.data-chapter-id]="paragraph.id">
                            {{ chapterIndex + 1 }}.{{ paragraphIndex + 1 }} {{ paragraph.translations[0].content }}
                        </h1>
                    </div>

                    <ab-component *ngFor="let component of paragraph.components" [component]="component"></ab-component>

                    <div *ngFor="let subParagraph of getParagraphs(paragraph); let subParagraphIndex = index"
                         class="subparagraph-wrapper" id="chapter_{{ subParagraph.id }}">
                        <div *ngIf="subParagraph.translations[0].content" class="content-wrap">
                            <h1>{{ chapterIndex + 1 }}.{{ paragraphIndex + 1 }}
                                                      .{{ subParagraphIndex + 1 }} {{ subParagraph.translations[0].content }}</h1>
                        </div>

                        <ab-component *ngFor="let component of subParagraph.components"
                                      [component]="component"></ab-component>
                    </div>
                </div>
            </div>

            <div *ngIf="contactPerson" class="content-wrap">
                <div (click)="authorDetails(contactPerson)" class="linked-profile">
                    <h2>Vragen over de aanpassingen?</h2>
                    <p>Heb je vragen over de aanpassingen? Neem dan contact op met {{ contactPerson.title }}.</p>
                    <div class="profile-card">
                        <img alt="{{ contactPerson.name }}" src="../assets/images/default-account.png">
                        <div class="column text">
                            <span class="name">{{ contactPerson.title }}</span>
                            <span class="label">Bekijk profiel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div (click)="chapterListActive = false" [ngClass]="{'active': chapterListActive}" class="overlay"></div>

        <div [ngClass]="{'active': chapterListActive}" class="chapters-list">
            <h3>{{ 'manual.chapters' | translate }}</h3>

            <ul *ngFor="let chapter of getChapters(); let i = index">
                <li (click)="scrollToChapter($event.target, chapter.id)" id="index_chapter_{{ chapter.id }}">
                    {{ i + 1 }}. {{ chapter.translations[0].content }}
                </li>

                <li *ngIf="chapter.chapters && chapter.chapters.length">
                    <ul *ngFor="let paragraph of getParagraphs(chapter); let j = index">
                        <li (click)="scrollToChapter($event.target, paragraph.id)" id="index_chapter_{{ paragraph.id }}">
                            {{ i + 1 }}.{{ j + 1}}. {{ paragraph.translations[0].content }}
                        </li>

                        <li *ngIf="paragraph.chapters && paragraph.chapters.length">
                            <ul *ngFor="let subParagraph of getParagraphs(paragraph); let k = index">
                                <li (click)="scrollToChapter($event.target, subParagraph.id)" id="index_chapter_{{ subParagraph.id }}">
                                    {{ i + 1 }}.{{ j + 1}}.{{ k + 1}}. {{ subParagraph.translations[0].content }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</ion-content>

<ion-footer [hidden]="!search.visible">
    <div class="search-box">
        <div class="search-input">
            <ion-input #searchInput (ionInput)="onSearch($event)" debounce="500"></ion-input>
            <span>{{ search.current }}/{{ search.total }}</span>
        </div>

        <div class="buttons">
            <div class="down" (click)="showSearchResult(-1)">
                <svg fill="none" height="8" viewBox="0 0 12 8" width="12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z" fill="black" />
                </svg>
            </div>
            <div class="up" (click)="showSearchResult(+1)">
                <svg fill="none" height="8" viewBox="0 0 12 8" width="12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z" fill="black" />
                </svg>
            </div>
            <div class="close" (click)="closeSearch()">
                <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                          fill="#0069B4" opacity="0.7" />
                </svg>
            </div>
        </div>
    </div>
</ion-footer>
