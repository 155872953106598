import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';

@Component({
    selector: 'pin-verification',
    templateUrl: './pin-verification.component.html',
    styleUrls: ['./pin-verification.component.scss'],
})
export class PinVerificationComponent implements OnInit {
    @ViewChild('pinInput') pinInput;

    @Output() pinEntered: EventEmitter<any> = new EventEmitter();

    private pinCode = '';

    constructor(
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.pinCode = '';
    }

    focusLogin() {
        this.pinInput.setFocus();
    }

    pinChange(): void {
        this.pinCode = this.pinInput.value;

        if (this.pinCode.length === 5) {
            this.authService.verifyPin(this.pinCode).subscribe(
                (res) => {
                    this.pinEntered.emit(true);
                },
                (err) => {
                    this.pinEntered.emit(false);
                },
            );

            this.pinCode = '';
            this.pinInput.value = '';
        }
    }

    isFilled(box) {
        if (box <= this.pinCode.length) {
            return 'pin-field filled';
        }

        return 'pin-field empty';
    }
}
