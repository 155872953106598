import { API_URL } from '../../environments/environment';
import { AlertService } from './alert/alert.service';

export class BaseHttpService {
    baseUrl = API_URL;

    // This method needs a promise because if you use a observable, you execute the request twice
    catchLaravelErrors<T>(promise: Promise<T>, alertService: AlertService) {
        promise.catch(({ status, error }) => {
                if (status === 422) {
                    Object.values(error.errors ?? {}).forEach((errors: string[]) => {
                        errors.forEach((error) => {
                            alertService.warning(error);
                        });
                    });
                }
            });

        return promise;
    }
}
