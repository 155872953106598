import { Component, OnInit } from '@angular/core';
import { Article } from '../../entities/Article';
import { BehaviorSubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth.service';
import { NewsService } from '../../services/news/news.service';
import { API_URL } from '../../../environments/environment';
import { skip, tap } from 'rxjs/operators';

@Component({
    selector   : 'app-news',
    templateUrl: './news.page.html',
    styleUrls  : ['./news.page.scss'],
})

export class NewsPage implements OnInit {
    public articles = Array<Article>();
    public tips = Array<Article>();
    fetched = new BehaviorSubject(false);

    public showRideInfo = false;

    public currentSlide = 1;

    private currentPage = 1;

    constructor(
        private authService: AuthService,
        private newsService: NewsService,
        private navController: NavController,
    ) {
    }

    ngOnInit() {
        this.authService.userProfileState.asObservable().pipe(
            skip(1),
            tap(() => {
                this.refreshNews();
                this.fetchTips();
            }),
        ).subscribe();

        this.refreshNews();
        this.fetchTips();
    }

    refreshNews() {
        this.currentPage = 1;
        this.articles = [];
        this.fetchNews();
    }

    fetchNews() {
        this.newsService.fetchNews(this.currentPage).subscribe(data => {
            data.data.forEach(item => {
                this.articles.push(new Article(item));
            });
            this.fetched.next(true);
            this.currentPage++;
        });
    }

    fetchTips() {
        this.tips = [];

        this.newsService.fetchTips().subscribe(data => {
            data.data.forEach(item => {
                this.tips.push(new Article(item));
            });
        });
    }

    newsDetail(article: Article) {
        this.navController.navigateForward([`/news/${article.id}`]);
    }

    authorDetails($event, author) {
        $event.stopPropagation();
        this.navController.navigateForward([`/authors/${author.id}`]);
    }

    loadData(event) {
        setTimeout(() => {
            this.fetched.subscribe(fetched => {
                if (fetched) {
                    event.target.complete();
                    this.fetched.next(false);
                }
            });

            this.fetchNews();

        }, 1000);
    }

    doRefresh(event) {
        setTimeout(() => {
            this.refreshNews();
            event.target.complete();
        }, 1000);
    }

    loadArticleSecureImage(article) {
        const token = this.authService.authState.getValue().token;

        return article.getFeaturedImagePath() + `?token=${token}`;
    }

    loadContactSecureImage(contact) {
        if (!contact.photo) {
            return '../assets/images/default-account.png';
        }

        const token = this.authService.authState.getValue().token;

        return `${API_URL}/files/contacts/${contact.photo}?token=${token}`;
    }

    nextImage() {
        if (this.currentSlide === this.tips.length) {
            return;
        }

        this.currentSlide++;
    }

    previousImage() {
        if (this.currentSlide === 1) {
            return;
        }

        this.currentSlide--;
    }

    getSlideWidth() {
        if (typeof document.getElementsByClassName('slider-item')[0] === 'undefined') {
            return 0;
        }

        return document.getElementsByClassName('slider-item')[0].clientWidth + 32;
    }

    imageTranslation() {
        const slideWidth = this.getSlideWidth();
        const translate = (slideWidth * (this.currentSlide - 1)) * -1;

        return {
            transform: `translateX(${translate}px)`,
        };
    }

    onScroll($event) {
        const element = document.getElementById('blue-header-background');
        if (element) {

            if ($event && $event.detail && $event.detail.scrollTop <= 2) {
                element.classList.remove('hide');
            } else {
                element.classList.add('hide');
            }
        }
    }
}

