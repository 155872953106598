import {NgModule} from '@angular/core';

@NgModule()
export class HelpersModule {

    /**
     * Get device language
     * e.g. nl, de, fr
     */
    public static getDeviceLanguage(): string {
        return navigator.language.split('-')[0];
    }

    /**
     * Get device country
     * e.g. NL, DE, FR
     */
    public static getDeviceCountry(): string {
        return navigator.language.split('-')[1];
    }

    /**
     * Get device locale
     * e.g. nl_NL, en_GB, fr_FR
     */
    public static getDeviceLocale(): string {
        return navigator.language;
    }
}
