<div [ngClass]="'component--' + getComponentTypeById(component.component_type_id)" class="component">

    <div class="content-wrap" *ngIf="component.translations.length">
        <h2 *ngIf="component.component_type_id === 1" [innerHTML]="component.translations[0].content"></h2>
        <h3 *ngIf="component.component_type_id === 2" [innerHTML]="component.translations[0].content"></h3>
        <div *ngIf="component.component_type_id === 3" [innerHTML]="component.translations[0].content | safeHtml" class="wysiwyg"></div>

        <ng-template [ngIf]="component.component_type_id === 6">
            <h3>{{ getPollData().question }}</h3>

            <div *ngFor="let option of pollData.answers"
                 class="poll-option"
                 [class.showing-progress]="showingPollResults"
                 [class.selected]="option.id === selectedOption || option.id === myVote"
                 (click)="selectVote(option.id)">
                <svg *ngIf="selectedOption !== option.id && option.id !== myVote" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.14286" y="1.14286" width="29.7143" height="29.7143" rx="14.8571" fill="white" stroke="#0069B4" stroke-width="2.28571" />
                </svg>

                <svg *ngIf="option.id === selectedOption || option.id === myVote" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.14286" y="1.14286" width="29.7143" height="29.7143" rx="14.8571" fill="#0069B4" stroke="#0069B4" stroke-width="2.28571" />
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M23.7316 11.8634C23.9105 12.043 24 12.261 24 12.5175C24 12.7741 23.9105 12.9921 23.7316 13.1717L15.4883 21.4449C15.3094 21.6245 15.0921 21.7143 14.8365 21.7143C14.5809 21.7143 14.3636 21.6245 14.1847 21.4449L9.41124 16.6542C9.23232 16.4746 9.14286 16.2565 9.14286 16C9.14286 15.7435 9.23232 15.5254 9.41124 15.3458L10.7148 14.0375C10.8938 13.8579 11.111 13.7681 11.3666 13.7681C11.6222 13.7681 11.8395 13.8579 12.0184 14.0375L14.8365 16.8754L21.1244 10.5551C21.3033 10.3755 21.5206 10.2857 21.7762 10.2857C22.0318 10.2857 22.2491 10.3755 22.428 10.5551L23.7316 11.8634Z"
                          fill="white" />
                </svg>

                <span class="option-text">{{ option.answer }}</span>

                <div class="progress" [style.width.%]="getVoteOptionPercentage(option.id)"></div>
                <span class="percentage-label">{{ getVoteOptionPercentage(option.id) }} %</span>
            </div>

            <div class="buttons">
                <button *ngIf="! myVote" [disabled]="!selectedOption" (click)="vote()">{{ 'poll.vote' | translate }}</button>
                <button class="green" (click)="togglePollResults()" *ngIf="profile.isAdmin()">
                    {{ (showingPollResults ? 'poll.hide-results' : 'poll.view-results') | translate }}
                </button>
            </div>
            <span class="total-votes" *ngIf="profile.isAdmin()">{{ 'poll.count' | translate : { count: totalVotes } }}</span>
        </ng-template>
    </div>

    <img *ngIf="component.component_type_id === 4 && component.translations.length"
         (click)="previewImage()"
         [src]="makeComponentImageUrl()"
    />
</div>
