import { Component, Output, EventEmitter } from '@angular/core';
import { AppEventsService as Events } from '../../services/app-events.service';
import { AuthService } from '../../services/auth/auth.service';
import { AuthUser } from '../../entities/AuthUser';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, Platform } from "@ionic/angular";
import { DocumentNavigationModalComponent } from "../document-navigation-modal/document-navigation-modal.component";

@Component({
    selector   : 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls  : ['./navbar.component.scss'],
})
export class NavbarComponent {
    public visible = true;
    public profile = this.authService.userProfileState.getValue();

    public menuItems = [];
    public baseMenuItems = [];
    @Output() menuStateChange = new EventEmitter();
    private modal: HTMLIonModalElement;

    constructor(
        public events: Events,
        private authService: AuthService,
        private translateService: TranslateService,
        private modalController: ModalController,
        private platform: Platform
    ) {
        this.events.subscribe('nav:hide', () => {
            this.visible = false;
        });

        this.events.subscribe('nav:show', () => {
            this.visible = true;
        });

        this.authService.userProfileState.subscribe((authUser: AuthUser) => {
            this.profile = authUser;
        });

        this.getMenuItems();
    }

    get isDesktop(): boolean {
        return this.platform.is('desktop');
    }

    async showDocumentsMenu() {
        this.modal = await this.modalController.create({
                component        : DocumentNavigationModalComponent,
                cssClass         : 'document-navigation-modal',
                animated         : true,
                breakpoints      : !this.isDesktop ? [0, 1] : undefined,
                initialBreakpoint: 1,
                canDismiss       : true,
                handle           : false,
                mode             : 'md',
                componentProps   : {
                    callback: this.changeStatus.bind(this),
                }
            }
        )

        return await this.modal.present();
    }

    async changeStatus() {
        await this.modal.dismiss();
    }

    async getMenuItems() {
        const translations = await this.translateService.get('navigation').toPromise();

        this.baseMenuItems = [
            {
                name             : translations.news,
                routerLink       : '/news',
                routerLinkOptions: { exact: true },
                icon             : 'baseline-photo-24px',
                hasIf            : true,
            },
            {
                name             : translations.contacts,
                routerLink       : '/contact-departments',
                routerLinkOptions: {},
                icon             : 'contacts',
                hasIf            : true,
            },
            {
                name             : translations.documents,
                routerLink       : null,
                routerLinkOptions: {},
                icon             : 'baseline-description-24px',
                hasIf            : true,
            }
        ].filter(item => {
            return item.hasIf;
        });

        this.menuItems = [
            {
                name             : translations.events,
                routerLink       : '/events',
                routerLinkOptions: {},
                icon             : 'cake',
                hasIf            : true,
            },
            {
                name             : translations.profile,
                routerLink       : '/profile',
                routerLinkOptions: {},
                icon             : 'mdi_account_circle',
                hasIf            : true,
            },
            {
                name             : translations['locations-on-route'],
                routerLink       : '/tank-locations-car-washes',
                routerLinkOptions: {},
                icon             : 'mdi_local_gas_station',
                hasIf            : this.profile.hasPermission('tanklocaties-wasstraten'),
            },
            {
                name             : translations.expenses,
                routerLink       : '/expenses',
                routerLinkOptions: {},
                icon             : 'mdi_expenses',
                hasIf            : this.profile.hasPermission('expenses'),
            },
            {
                name             : translations.colleagues,
                routerLink       : '/colleagues',
                routerLinkOptions: {},
                icon             : 'contacts',
                hasIf            : true,
            },
            {
                name             : translations.ideas,
                routerLink       : '/ideas',
                routerLinkOptions: {},
                icon             : 'ideas',
                hasIf            : true,
            },
            {
                name             : translations.furlough,
                routerLink       : '/furloughs',
                routerLinkOptions: {},
                icon             : 'furlough',
                hasIf            : this.profile.hasPermission('furlough'),
            },
            {
                name             : translations['my-service-schedule'],
                routerLink       : '/content-page/1',
                routerLinkOptions: {},
                icon             : 'calendar-nav',
                hasIf            : true,
            },
            {
                name             : translations['my-favorite-articles'],
                routerLink       : '/news/favorite',
                routerLinkOptions: { exact: true },
                icon             : 'baseline-photo-24px',
                hasIf            : true,
            }
        ].filter(item => {
            return item.hasIf;
        }).sort((a, b) => (a.name < b.name ? -1 : 1));

        return translations;
    }

    toggleMenu() {
        this.menuStateChange.emit();
    }
}
