import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { NewsDetailPage } from './news-detail.page';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from '../../components/component.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

const routes: Routes = [
    {
        path     : '',
        component: NewsDetailPage
    }
];

@NgModule({
    imports     : [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ComponentModule,
        TranslateModule,
        AngularSvgIconModule,
    ],
    declarations: [NewsDetailPage]
})
export class NewsDetailPageModule {
}
