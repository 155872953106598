import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";

@Component({
    selector   : 'app-document-navigation-modal',
    templateUrl: './document-navigation-modal.component.html',
    styleUrls  : ['./document-navigation-modal.component.scss']
})
export class DocumentNavigationModalComponent {

    @Input() callback: any;

    constructor(private router: Router) {
    }

    navigateTo(link: string) {
        this.callback('close');
        this.router.navigate([link]);
    }
}
