import { Component, OnInit } from '@angular/core';
import { BrowserService } from '../../../services/browser/browser.service';

@Component({
  selector: 'app-work-clothing',
  templateUrl: './work-clothing.page.html',
  styleUrls: ['./work-clothing.page.scss'],
})
export class WorkClothingPage implements OnInit {

  constructor(public browserService: BrowserService) { }

  ngOnInit() {
  }
}
