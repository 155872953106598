import {Injectable} from '@angular/core';
import {BaseHttpService} from '../base-http.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SalariesService extends BaseHttpService {

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    fetch() {
        return this.http.get<any>(`${this.baseUrl}/salaries`);
    }
}
