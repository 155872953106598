import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(private toastController: ToastController, private translateService: TranslateService) {
    }

    async warning(message: any, duration = 2000) {

        //done this way because otherwise there is no way to customize
        let messageText = '<img src="assets/icon/danger.svg"><span>    </span><span style="padding-left: 12px">' + message + '</span>';

        const toast = await this.toastController.create({
            message: messageText,
            duration,
            position: 'top',
            color: 'danger',
            cssClass: 'error'
        });

        await toast.present();
    }

    async success(message: any, duration = 2000) {
        let messageText = '<img src="assets/icon/circle-checkmark-white.svg"><span>    </span><span style="padding-left: 12px">' + message + '</span>';
        const toast = await this.toastController.create({
            message: messageText,
            duration,
            position: 'top',
            color: 'success',
            cssClass: 'success'
        });

        await toast.present();
    }

    async laravelErrorMessage(err: HttpErrorResponse, duration = 2000) {
        const firstError = Object.keys(err.error.errors)[0];
        const errorMessage = err.error.errors[firstError];

        this.translateService.get(firstError).subscribe(translation => {
            // No translation found, show laravel error.
            if (firstError === translation) {
                this.warning(errorMessage, duration);
                return;
            }
            this.warning(translation, duration);
        });
    }

    async accoded(message: any, duration = 2000) {
        let messageText = '<span>' + message + ' &#128512;</span>';
        const toast = await this.toastController.create({
            message: messageText,
            duration,
            position: 'top',
            color: 'success',
            cssClass: 'success'
        });

        await toast.present();
    }
}
