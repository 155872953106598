import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseHttpService} from '../base-http.service';

@Injectable({
    providedIn: 'root'
})
export class PollService extends BaseHttpService {

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    getVoteResults(pollId) {
        return this.http.get<any>(`${this.baseUrl}/poll/${pollId}/results`);
    }

    vote(pollId, optionId) {
        const params = {
            poll_id: pollId,
            option_id: optionId,
        };

        return this.http.post(`${this.baseUrl}/poll/vote`, params);
    }
}
