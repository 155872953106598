import { Component, OnInit } from '@angular/core';
import { Article } from '../../../entities/Article';
import { NewsService } from '../../../services/news/news.service';
import { BehaviorSubject } from 'rxjs';
import { NavController } from '@ionic/angular';
import { API_URL } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
    selector   : 'app-favorite',
    templateUrl: './favorite.page.html',
    styleUrls  : ['../news.page.scss'],
})
export class FavoritePage implements OnInit {

    public currentPage = 1;
    public articles = Array<Article>();
    fetched = new BehaviorSubject(false);

    constructor(private newsService: NewsService, private navController: NavController, private authService: AuthService) {
    }

    onScroll($event) {
        const element = document.getElementById('blue-header-background');
        if (element) {

            if ($event && $event.detail && $event.detail.scrollTop <= 2) {
                element.classList.remove('hide');
            } else {
                element.classList.add('hide');
            }
        }
    }

    doRefresh(event) {
        setTimeout(() => {
            this.refreshFavoriteArticles();
            event.target.complete();
        }, 1000);
    }

    refreshFavoriteArticles() {
        this.currentPage = 1;
        this.articles = [];
        this.fetchFavoriteArticles();
    }

    authorDetails($event, author) {
        $event.stopPropagation();
        this.navController.navigateForward([`/authors/${author.id}`]);
    }

    loadContactSecureImage(contact) {
        if (!contact.photo) {
            return '../assets/images/default-account.png';
        }

        const token = this.authService.authState.getValue().token;

        return `${API_URL}/files/contacts/${contact.photo}?token=${token}`;
    }

    ngOnInit() {
        this.refreshFavoriteArticles();
    }

    fetchFavoriteArticles() {
        this.newsService.fetchFavoriteArticles(this.currentPage).subscribe(data => {
            data.data.forEach(item => {
                this.articles.push(new Article(item));
            });
            this.fetched.next(true);
            this.currentPage++;
        });
    }

    loadArticleSecureImage(article) {
        const token = this.authService.authState.getValue().token;

        return article.getFeaturedImagePath() + `?token=${token}`;
    }

    newsDetail(article: Article) {
        this.navController.navigateForward([`/news/${article.id}`]);
    }

    loadData(event) {
        setTimeout(() => {
            this.fetched.subscribe(fetched => {
                if (fetched) {
                    event.target.complete();
                    this.fetched.next(false);
                }
            });

            this.fetchFavoriteArticles();

        }, 1000);
    }

}
