import {ContactPerson} from './ContactPerson';

export class Contact {
    id: number;
    title: number;
    subTitle: string;
    email: string;
    phone: string;
    photo: string;
    contact_persons: ContactPerson[] = [];

    constructor(item) {
        if (item !== null) {
            this.id = item.id;
            this.title = item.title;
            this.subTitle = item.sub_title;
            this.email = item.email;
            this.phone = item.phone;
            this.photo = item.photo;
            this.contact_persons = item.contact_persons;
        }
    }
}
