import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';

import {MenuSliderComponent} from './menu-slider/menu-slider.component';
import {PinVerificationComponent} from './pin-verification/pin-verification.component';
import {WholeScreenNotificationComponent} from './whole-screen-notification-component/whole-screen-notification-component.component';
import {ProfileImageComponent} from './profile-image/profile-image.component';
import {AbComponentComponent} from './ab-component/ab-component.component';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserMessageComponent} from './browser-message/browser-message.component';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {ucFirstPipe} from '../pipes/ucfirst.pipe';
import { FavoriteNewsArticleComponent } from "./favorite-news-article/favorite-news-article.component";

@NgModule({
    declarations: [
        MenuSliderComponent,
        WholeScreenNotificationComponent,
        PinVerificationComponent,
        ProfileImageComponent,
        AbComponentComponent,
        BrowserMessageComponent,
        SafeHtmlPipe,
        ucFirstPipe,
        FavoriteNewsArticleComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
    ],
    exports: [
        MenuSliderComponent,
        WholeScreenNotificationComponent,
        FavoriteNewsArticleComponent,
        PinVerificationComponent,
        ProfileImageComponent,
        AbComponentComponent,
        BrowserMessageComponent,
        SafeHtmlPipe,
        ucFirstPipe,
    ],
})
export class ComponentModule {
}
