import {Component, OnInit} from '@angular/core';
import {TermsService} from '../../services/terms/terms.service';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.page.html',
    styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage implements OnInit {

    public privacyPolicy = null;

    constructor(private termsService: TermsService, private navController: NavController) {
    }

    acceptTerms() {
        this.termsService.acceptTerms().subscribe(then => {
            this.navController.navigateRoot(['/']);
        });
    }

    ngOnInit(): void {
        this.termsService.getTerms().subscribe(data => {
            this.privacyPolicy = data.privacyPolicy;
        });
    }

}
