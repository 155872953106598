import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ucFirst'
})
export class ucFirstPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string, ...args: any[]): any {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}
