import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { ManualChangesPage } from './manual-changes.page';
import {TranslateModule} from '@ngx-translate/core';
import {ComponentModule} from '../../components/component.module';

const routes: Routes = [
  {
    path: '',
    component: ManualChangesPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        ComponentModule,
    ],
  declarations: [ManualChangesPage]
})
export class ManualChangesPageModule {}
