<ion-app>
    <div class="modal" *ngIf="anniversary && anniversary.length > 0">
        <div class="modal-overlay"></div>

        <div class="modal-content">
            <svg class="close" (click)="anniversary = null" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="40" height="40" rx="20" fill="url(#paint0_linear)" />
                <path d="M27 14.41L25.59 13L20 18.59L14.41 13L13 14.41L18.59 20L13 25.59L14.41 27L20 21.41L25.59 27L27 25.59L21.41 20L27 14.41Z" fill="black" />
                <defs>
                    <linearGradient id="paint0_linear" x1="40" y1="0" x2="-6.47832" y2="29.9448" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0069B4" />
                        <stop offset="1" stop-color="#0B578E" />
                    </linearGradient>
                </defs>
            </svg>

            <h2 *ngIf="anniversary.self">{{ 'anniversary-modal.hey' | translate : { name: anniversary[0].name } }}</h2>
            <p *ngIf="anniversary.self">{{ 'anniversary-modal.congrats-self' | translate : { years: anniversary[0].anniversaryType } }}</p>

            <h2 *ngIf="! anniversary.self && anniversary.length === 1">{{ 'anniversary-modal.congrats-colleague' | translate : {
                name: anniversary[0].name,
                years: anniversary[0].anniversaryType
            } }}</h2>

            <h2 *ngIf="! anniversary.self && anniversary.length > 1">{{ 'anniversary-modal.congrats-colleagues-title' | translate }}</h2>
            <p *ngIf="! anniversary.self && anniversary.length > 1">{{ 'anniversary-modal.congrats-colleagues' | translate : { names: anniversary.firstPersons, last: anniversary.lastPerson } }}</p>

            <div class="blue-circle">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 8L1.41 9.41L7 3.83V16H9V3.83L14.58 9.42L16 8L8 0L0 8Z" fill="white" />
                </svg>

                <span (click)="moreConfetti()">{{ 'anniversary-modal.more-confetti' | translate }}</span>
            </div>
        </div>
    </div>
    <app-accoding-handbooks></app-accoding-handbooks>
    <ion-router-outlet></ion-router-outlet>
</ion-app>
