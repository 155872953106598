import { Component, NgZone, ViewChild } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService, authStateKey } from './services/auth/auth.service';
import { map, skip, tap } from 'rxjs/operators';
import { AuthState } from './entities/AuthState';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { HelpersModule } from './modules/helpers/helpers.module';
import moment from 'moment';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AccountsService } from './services/accounts/accounts.service';
import { environment } from '../environments/environment';
import JSConfetti from "js-confetti";
import { Router } from "@angular/router";
import { register } from 'swiper/element/bundle';
import * as Sentry from "@sentry/browser";
import packageJson from '../../package.json';

const lastViewedBirthdayDate = 'lastViewedBirthdayDate';

register();

@Component({
    selector   : 'app-root',
    templateUrl: 'app.component.html',
    styleUrls  : ['app.component.scss']
})
export class AppComponent {
    public date = null;
    public anniversary = null;
    private redirectUrl = null;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authService: AuthService,
        private accountsService: AccountsService,
        private navController: NavController,
        private storage: Storage,
        private translate: TranslateService,
        private firebase: FirebaseX,
        private ngZone: NgZone,
        private router: Router,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.translate.setDefaultLang('en');
        this.translate.use(HelpersModule.getDeviceLanguage());

        this.platform.ready().then(() => {
            const version = packageJson.version;
            console.log('App version: ', version);

            Sentry.init({
                dsn    : 'https://58fd9803963cf8e44c699c824002c952@o349358.ingest.us.sentry.io/4507764059734016',
                release: `AB-Mobile@${version}`,
            });
            console.log('Sentry initialized with version: ', version);

            this.statusBar.styleDefault();
            this.splashScreen.hide();

            if (!this.platform.is('cordova')) {
                this.setRedirectUrl();
            }

            this.setAuthState();

            if (environment.production === true) {
                if (!this.platform.is('cordova')) {
                    this.authService.logout();
                } else {
                    if (!this.date) {
                        this.authService.logout();
                    }

                    setTimeout(() => {
                        this.setPauseAppEvents();
                    }, 1000);
                }
            }
        });
    }

    /**
     * Set application authentication state
     */
    setAuthState() {
        this.authService.authState.pipe(
            tap((authState: AuthState) => {
                if (!authState.isChanged() || this.router.getCurrentNavigation()?.extractedUrl?.toString() === '/public/privacy-policy') {
                    return;
                }

                if (!authState.isAuthenticated()) {
                    if (!authState.hasPhone()) {
                        this.navController.navigateForward([`/request-login`]);
                    }

                    if (authState.hasPhone() && !authState.hasTwoFactorToken()) {
                        this.navController.navigateForward([`/sms-login`]);
                    }

                    if (authState.hasTwoFactorToken() && !authState.hasSetPin()) {
                        if (!authState.pin) {
                            this.navController.navigateRoot(['/set-pin']);
                        } else {
                            this.navController.navigateForward(['/confirm-pin']);
                        }
                    }

                    if (authState.hasTwoFactorToken() && authState.hasSetPin()) {
                        this.navController.navigateRoot(['/login']);
                    }
                }

                if (authState.isAuthenticated()) {
                    if (authState.isRefreshTokenExpired()) {
                        this.navController.navigateRoot([`/login`]);
                        return;
                    }

                    if (!authState.authUser.has_accepted_terms) {
                        this.navController.navigateRoot(['/terms-and-conditions']);
                        return;
                    }

                    this.grantFirebasePermissions();

                    if (this.redirectUrl) {
                        setTimeout(() => {
                            this.navController.navigateRoot(this.redirectUrl);
                        }, 50);
                    }

                    this.loggedIn();
                }
            }),
            map((authState: AuthState) => {
                authState.authChanged = false;

                return authState;
            }),
        ).subscribe((authState: AuthState) => {
            this.storage.set(authStateKey, authState);
        });
    }

    /**
     * Triggered functions after application is paused or resumed.
     */
    setPauseAppEvents() {
        this.platform.pause.subscribe(() => {
            // Set last active date in UTC
            this.date = new Date().toISOString();
        });

        this.platform.resume.subscribe(() => {
            this.ngZone.run(async () => {
                // Redirect to login on resume when the app has been inactive for longer than 5 minutes.
                if (moment.utc(this.date).isBefore(moment.utc().subtract(5, 'minutes'))) {
                    if (this.authService.isAuthenticated()) {
                        this.authService.logout();
                    }
                }
            });
        });
    }

    grantFirebasePermissions() {
        if (!this.authService.isUserActive()) {
            return;
        }

        this.firebase.grantPermission()
            .then(() => {
                this.permissionGranted();
            })
            .catch(() => {
                this.firebase.hasPermission().then(() => {
                    this.permissionGranted();
                });
            });
    }

    permissionGranted() {
        this.firebase.getToken().then(token => {
            this.accountsService.storeDeviceToken(token).subscribe();
        });
    }

    setRedirectUrl() {
        const params = (new URL(window.location.href)).searchParams;
        const redirectUrl = params.get('redirectUrl');

        if (redirectUrl) {
            const url = new URL(redirectUrl);
            this.redirectUrl = url.pathname + url.search;
        }
    }

    loggedIn() {
        this.loggedIn = () => {
        };
        this.authService.todayAnniversaries.asObservable().pipe(
            skip(1),
            tap(async (data) => {
                // Check whether the user has viewed the birthday notification today
                // By comparing the current date with the last viewed date from the storage
                const currentDate = moment().format('YYYY-MM-DD');
                const lastViewedDate = await this?.storage?.get(lastViewedBirthdayDate) ?? null;

                if (lastViewedDate !== currentDate) {
                    this.showTodayAnniversaries(data);

                    this.storage.set(lastViewedBirthdayDate, currentDate);
                }
            }),
        ).subscribe();

        this.authService.updateTodayAnniversaries();
    }

    showTodayAnniversaries(data) {
        if (data.date !== moment().format('YYYY-MM-DD') || data.isNotified || !data.items.length) {
            return;
        }

        // Check if the authenticated user has anniversary
        const authUserId = this.authService.userProfileState.getValue().id;
        const isAuthUserAnniversary = data.items.find(i => {
            return i.id === authUserId && moment().format('MM-DD') === i.getMomentEmploymentStartDate().format('MM-DD');
        });
        this.anniversary = isAuthUserAnniversary ? [isAuthUserAnniversary] : data.items;
        this.anniversary.self = !!isAuthUserAnniversary;
        this.anniversary.firstPersons = data.items.slice(0, -1).map(i => i.name).join(', ');
        this.anniversary.lastPerson = data.items.slice(-1).pop().name;

        this.authService.markTodayAnniversariesAsRead();

        this.setConfetti();
    }

    setConfetti() {
        setTimeout(() => {
            const jsConfetti = new JSConfetti();
            jsConfetti.addConfetti();
        }, 1500);
    }

    moreConfetti() {
        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti();
    }
}
