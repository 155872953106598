<ion-header no-border>
    <ion-toolbar class="ion-padding-horizontal">
        <profile-image></profile-image>
        <ion-title>{{ 'profile.work-clothing' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>


<ion-content>
    <div class="wrapper-div">
        <h1 class="desktop">{{ 'profile.work-clothing' | translate }}</h1>
        <div class="content-wrap ion-margin-top">
            <p>
                <strong>{{ 'profile.login-details' | translate }}</strong><br>
                {{ 'profile.username' | translate }}: abt&lt;{{ 'profile.staff-no' | translate }}&gt;<br>
                {{ 'profile.password' | translate }}: abt&lt;{{ 'profile.first-three-letters-of-the-surname' | translate }}&gt;
            </p>
            <p>
                <strong>{{ 'profile.example' | translate }}</strong><br>
                Piet de Vries, {{ 'profile.staff-no' | translate }} 123456<br>
                {{ 'profile.username' | translate }}: abt123465<br>
                {{ 'profile.password' | translate }}: abtvri
            </p>

            <ion-button (click)="browserService.openLink('https://kms3.zijlstraberoepskleding.nl/u/inloggen')" class="show-lng-stations">
                {{ 'profile.order-work-clothing' | translate }}
            </ion-button>

            <div class="spacer"></div>
        </div>
    </div>
</ion-content>

<ion-content>

</ion-content>
