import { Component, Input } from '@angular/core';

@Component({
    selector   : 'accode-document-modal',
    templateUrl: './accode-document-modal.component.html',
    styleUrls  : ['./accode-document-modal.component.scss']
})
export class AccodeDocumentModalComponent {
    @Input() callback: any;
    @Input() accodeById: number;


    approve() {
        this.callback()
    }
}
