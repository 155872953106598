import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NavController} from '@ionic/angular';
import {API_URL} from '../../../environments/environment';
import {AuthService} from '../../services/auth/auth.service';
import {SettingsService} from '../../services/settings/settings.service';

@Component({
    selector: 'app-push-notifications-page',
    templateUrl: './push-notifications.page.html',
    styleUrls: ['./push-notifications.page.scss'],
})

export class PushNotificationsPage implements OnInit {
    public isDataLoaded = false;

    public notifications = {
        salary: {
            title: 'Salarisstroken en loonbijlagen',
            enabled: false
        },
        news: {
            title: 'Nieuws',
            enabled: false
        },
    };

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private navController: NavController,
        private settingsService: SettingsService,
    ) {
    }

    ngOnInit() {
        this.settingsService.fetchSettings().subscribe((data) => {
            for (const [key, value] of Object.entries(data)) {
                this.notifications[key] = {...this.notifications[key], value};
            }
            this.isDataLoaded = true;
        });
    }

    goBack() {
        this.navController.pop();
    }

    toggleNotification(event, notification, key) {
        notification.enabled = event;
        this.notifications[key].enabled = event;

        this.settingsService.persistNotifications(this.notifications).subscribe(() => {
        });
    }
}
