import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Storage} from '@ionic/storage';
import {AuthState} from '../../entities/AuthState';
import {API_URL} from '../../../environments/environment';
import {AuthUser} from '../../entities/AuthUser';
import {AccountsService} from '../accounts/accounts.service';
import {TodayAnniversaries} from '../../entities/TodayAnniversaries';
import {ColleaguesService} from '../colleagues/colleagues.service';
import moment from 'moment';

export const authStateKey = 'authStateTest';
export const todayAnniversariesKey = 'anniversaries';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authState = new BehaviorSubject<AuthState>(
        new AuthState(false, false, null, null, null, null, 1, 1, true)
    );

    userProfileState = new BehaviorSubject<AuthUser>(
        new AuthUser(null)
    );

    todayAnniversaries = new BehaviorSubject<TodayAnniversaries>(
        new TodayAnniversaries(null)
    );

    requestLoginUrl = `${API_URL}/v2/auth/request-login`;
    twoFactorPinLogin = `${API_URL}/v2/auth/two-fa-pin-login`;
    loginUrl = `${API_URL}/v2/auth/login`;
    setPinUrl = `${API_URL}/v2/auth/reset-pin`;
    clearPinUrl = `${API_URL}/v2/auth/clear-pin`;
    refreshTokenUrl = `${API_URL}/v2/auth/refresh-token`;
    verifyPinUrl = `${API_URL}/v2/auth/verify-pin`;
    logOutUrl = `${API_URL}/v2/auth/logout`;

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private accountsService: AccountsService,
        private colleaguesService: ColleaguesService) {
    }

    async setAuthState() {
        let storageAuthState = await this.storage.get(authStateKey);
        let storageTodayAnniversariesState = await this.storage.get(todayAnniversariesKey);
        storageAuthState = storageAuthState || AuthState.makeEmpty();
        storageTodayAnniversariesState = storageTodayAnniversariesState || new TodayAnniversaries(null);
        this.todayAnniversaries.next(new TodayAnniversaries(storageTodayAnniversariesState));

        const authState = AuthState.makeFromObject(storageAuthState);
        const userProfileState = storageAuthState.authUser ? new AuthUser(storageAuthState.authUser) : new AuthUser(null);

        // If user is authenticated update his account data
        if (authState.isAuthenticated()) {
            this.accountsService.profile().pipe(
                tap((data: any) => {
                    this.updateUserDataState(new AuthUser(data));
                }),
            );
        }

        this.authState.next(authState);
        this.userProfileState.next(userProfileState);
    }

    completeLogout() {
        const authState = AuthState.makeEmpty();
        authState.authChanged = true;

        this.http.post(this.logOutUrl, {}).subscribe();

        this.authState.next(authState);
    }

    forceLogout() {
        const  authState = AuthState.makeEmpty();
        authState.authChanged = true;
        this.authState.next(authState);
    }

    logout() {
        const authState = this.authState.getValue();

        if (!authState.token) {
            return;
        }

        if (!authState.hasTwoFactorToken()) {
            this.completeLogout();
            return;
        }

        this.http.post(this.logOutUrl, {}).subscribe();

        authState.setToken(null);
        authState.setAuth(false);
        this.authState.next(authState);
    }

    requestLogin(phone: string, type = 'sms'): Observable<any> {
        const params = {
            phone,
            type,
            device_token: this.authState.getValue().deviceAppToken,
        };

        return this.http.post(`${this.requestLoginUrl}`, params);
    }

    setPhoneState(phone: string) {
        const authState = this.authState.getValue();
        authState.setPhone(phone);
        this.authState.next(authState);
    }

    loginWithSms(phone: string, pin: string) {
        const params = {
            phone,
            pin,
            device_token: this.authState.getValue().deviceAppToken,
        };

        return this.http.post(`${this.twoFactorPinLogin}`, params)
            .pipe(
                tap((res: any) => {
                    const authState = this.authState.getValue();
                    authState.setTwoFactorToken(res.token);
                    authState.setPinState(res.has_personal_pin);
                    this.authState.next(authState);
                })
            );
    }

    setNewPinState(pin: string) {
        const authState = this.authState.getValue();
        authState.setPin(pin);
        this.authState.next(authState);
    }

    clearPinCodes() {
        const authState = this.authState.getValue();
        authState.setPin(null);
        authState.setPinState(false);
        this.authState.next(authState);
    }

    forgotPin() {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${this.authState.getValue().twoFactorToken}`
            })
        };

        return this.http.put(`${this.clearPinUrl}`, {}, httpOptions)
            .pipe(
                tap((res: any) => {
                    this.completeLogout();
                })
            ).subscribe();
    }

    setNewPin(pin: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${this.authState.getValue().twoFactorToken}`
            })
        };

        return this.http.put(`${this.setPinUrl}`, {pin}, httpOptions)
            .pipe(
                tap((res: any) => {
                    const authState = this.authState.getValue();
                    authState.setToken(res.token);
                    authState.setAuth(true);
                    authState.setAuthUserState(new AuthUser(res.user));
                    authState.setTokenExpiration(res.expires);
                    authState.setRefreshTokenExpiration(res.refreshExpires);
                    this.authState.next(authState);
                })
            );
    }

    setPinState(state: boolean) {
        const authState = this.authState.getValue();
        authState.setPinState(true);
        authState.setPin(null);
        this.authState.next(authState);
    }

    login(phone: string, pin: string) {
        const params = {
            phone,
            pin,
        };

        return this.http.post(`${this.loginUrl}`, params)
            .pipe(
                tap((res: any) => {
                    const authState = this.authState.getValue();
                    const authUser = new AuthUser(res.user);
                    authState.setToken(res.token);
                    authState.setAuth(true);
                    authState.setAuthUserState(authUser);
                    authState.setTokenExpiration(res.expires);
                    authState.setRefreshTokenExpiration(res.refreshExpires);
                    this.authState.next(authState);
                    this.userProfileState.next(authUser);
                })
            );
    }

    refreshToken() {
        return this.http.post(`${this.refreshTokenUrl}`, {}).pipe(
            tap((res: any) => {
                const authState = this.authState.getValue();
                authState.setToken(res.token);
                authState.setTokenExpiration(res.expires);
                authState.setRefreshTokenExpiration(res.refreshExpires);
                this.authState.next(authState);
            })
        );
    }

    isAuthenticated() {
        return this.authState.getValue().isAuthenticated();
    }

    isUserActive() {
        return this.authState.getValue().authUser.is_active;
    }

    hasUserValidDeactivation() {
        return this.authState.getValue().authUser.has_valid_deactivation;
    }

    isPinSet() {
        const authState = this.authState.getValue();

        return authState.hasSetPin();
    }

    verifyPin(pin) {
        return this.http.post(`${this.verifyPinUrl}`, {pin});
    }

    fetchAuthUser(): AuthUser {
        const authState = this.authState.getValue();

        return authState.authUser;
    }

    updateUserDataState(authUser: AuthUser) {
        const authState = this.authState.getValue();
        authState.setAuthUserState(authUser);
        this.authState.next(authState);
        this.userProfileState.next(authUser);
    }

    updateTodayAnniversaries() {
        const comparatorYear = moment().format('MM-DD');
        const comparatorHalfYear = moment().subtract('months', 6).format('MM-DD');

        this.colleaguesService.fetchAnniversaries().subscribe((data) => {
            const date = moment().format('YYYY-MM-DD');
            const current = this.todayAnniversaries.getValue();

            current.addItems(
                date,
                data.current_week.filter(d => {
                    return [comparatorYear, comparatorHalfYear].includes(moment(d.employment_start_date).format('MM-DD'));
                })
            );

            this.todayAnniversaries.next(current);
            this.storage.set(todayAnniversariesKey, current);
        });
    }

    markTodayAnniversariesAsRead() {
        const current = this.todayAnniversaries.getValue();
        current.isNotified = true;
        this.todayAnniversaries.next(current);
        this.storage.set(todayAnniversariesKey, current);
    }
}
