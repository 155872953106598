import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WorkClothingPage } from './work-clothing.page';
import { ComponentModule } from '../../../components/component.module';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentModule,
    TranslateModule,
  ],
  declarations: [WorkClothingPage]
})
export class WorkClothingPageModule {}
