<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title>{{ 'navigation.documents' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<div class="container">
    <div class="document-modal-wrapper">
        <div class="item" (click)="navigateTo('/profile/personal-documents')">
            <img src="/assets/images/documents-modal/personal-documents.svg" />
            <div class="content">
                <span>{{ 'profile.personal-documents' | translate }}</span>
            </div>
        </div>
        <div class="item" (click)="navigateTo('/manuals')">
            <img src="/assets/images/documents-modal/manual.svg" />
            <div class="content">
                <span>{{ 'navigation.instructions' | translate }}</span>
            </div>
        </div>
        <div class="item" (click)="navigateTo('/documents')">
            <img src="/assets/images/documents-modal/documents.svg" />
            <div class="content">
                <span>{{ 'navigation.documents' | translate }}</span>
            </div>
        </div>
        <div class="item" (click)="navigateTo('/reports-list')">
            <img src="/assets/images/documents-modal/reports.svg" />
            <div class="content">
                <span>{{ 'navigation.reports' | translate }}</span>
            </div>
        </div>
    </div>
</div>
