import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {FilePreviewPage} from './file-preview.page';
import {ComponentModule} from '../../components/component.module';
import {TranslateModule} from '@ngx-translate/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { PinchZoomModule } from 'ngx-pinch-zoom';


const routes: Routes = [
    {
        path: '',
        component: FilePreviewPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ComponentModule,
        TranslateModule,
        PdfViewerModule,
        PinchZoomModule
    ],
    declarations: [FilePreviewPage]
})
export class FilePreviewModule {
}
