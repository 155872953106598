import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-public-privacy-policy',
    templateUrl: './public-privacy-policy.page.html',
    styleUrls: ['./public-privacy-policy.page.scss'],
})
export class PublicPrivacyPolicyPage implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
