import { Component, Input } from '@angular/core';
import { NewsService } from '../../services/news/news.service';

@Component({
    selector   : 'favorite-news-article',
    templateUrl: './favorite-news-article.component.html',
    styleUrls  : ['./favorite-news-article.component.scss'],
})
export class FavoriteNewsArticleComponent {

    @Input() isFavorite: boolean;
    @Input() articleId: number;

    constructor(private newsService: NewsService) {
    }

    toggleFavorite() {
        this.isFavorite = !this.isFavorite;

        this.newsService.toggleFavorite(this.articleId, this.isFavorite).subscribe();
    }

}
