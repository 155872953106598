import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {

    constructor(
        private iab: InAppBrowser,
        private platform: Platform) {
    }

    openLink(link: string) {
        if (this.platform.is('ios')) {
            this.iab.create(link, '_system');
        } else {
            window.open(link, '_blank');
        }
    }
}
