import moment from 'moment';

export class Anniversary {
    id: number;
    name: string;
    employment_start_date: moment.Moment;
    employment_end_date: moment.Moment;
    employment_end_code: number;
    business_unit: Object;
    employee_function: Object;
    anniversaryType: string;
    anniversaryDate: moment.Moment;

    constructor(item) {
        if (!item) {
            return;
        }

        this.id = item.id;
        this.name = item.name;
        this.employment_start_date = item.employment_start_date || null;
        this.employment_end_date = item.employment_end_date || null;
        this.business_unit = item.business_unit || {};
        this.employee_function = item.employee_function || {};
        this.anniversaryType = this.calculateAnniversaryType(item.anniversary);
        this.anniversaryDate = this.calculateAnniversaryDate(item.anniversary);
        this.employment_end_code = item.employment_end_code != null ? parseInt(item.employment_end_code) : null;
    }

    calculateAnniversaryType(anniversary) {
        if (anniversary === null) {
            return null
        }
        const split = anniversary.split('.')
        return Number(split[1]) !== 0 ? anniversary : split[0];
    }

    calculateAnniversaryDate(anniversary) {
        if (anniversary === null || !this.employment_start_date) {
            return null;
        }
        const split = anniversary.split('.')
        const date = moment(this.employment_start_date)
        return Number(split[1]) !== 0 ? date.add(6, 'M') : date;
    }

    getMomentEmploymentStartDate() {
        return this.employment_start_date ? moment(this.employment_start_date) : null;
    }

    getMomentEmploymentEndDate() {
        return this.employment_end_date ? moment(this.employment_end_date) : null;
    }
}
