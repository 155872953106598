import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {MasterPage} from './master.page';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {NavbarComponent} from '../../components/navbar/navbar.component';
import {ComponentModule} from '../../components/component.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AngularSvgIconModule,
        RouterModule.forChild([
            {
                path: '',
                component: MasterPage
            }
        ]),
        ComponentModule,
        TranslateModule,
    ],
    declarations: [MasterPage, NavbarComponent]
})
export class MasterPageModule {
}
