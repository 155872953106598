<ion-header no-border>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button class="black" (click)="goBack()" defaultHref="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title (click)="goBack()">Notifications</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="wrapper-div">
        <h1 class="desktop">{{ 'notifications.title' | translate }}</h1>
        <div class="push-notifications-page-wrapper">

                <ion-item *ngFor="let notification of notifications | keyvalue">
                    <ion-label>{{ notification.value.title }}</ion-label>
                    <ion-toggle (ngModelChange)="toggleNotification($event, notification, notification.key)"
                                [(ngModel)]="notification.value.enabled"></ion-toggle>
                </ion-item>

        </div>
    </div>
</ion-content>
