<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button (click)="goBack()" defaultHref="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title (click)="goBack()"></ion-title>
    </ion-toolbar>

    <div *ngIf="isDataLoaded" class="author-profile">
        <img [src]="loadContactSecureImage(contact)">
        <h2>{{ contact.title }}</h2>
        <p *ngIf="contact.subTitle">{{ contact.subTitle }}</p>
    </div>
</ion-header>

<ion-content *ngIf="isDataLoaded">
    <div class="wrapper-div">
        <div class="author-contact-methods desktop-box-wrap">
            <div (click)="call()" *ngIf="contact.phone" class="author-contact author-contact--phone">
                {{ 'authors.call' | translate }}
            </div>

            <div (click)="mail()" *ngIf="contact.email" class="author-contact author-contact--email">
                {{ 'authors.email' | translate }}
            </div>
        </div>
    </div>
</ion-content>
