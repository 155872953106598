<ion-header no-border>
    <ion-toolbar class="ion-padding-horizontal">
        <ion-buttons slot="start">
            <ion-back-button text=""></ion-back-button>
        </ion-buttons>

        <ion-title>
            {{ 'terms-and-conditions.privacy-policy' | translate }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div [innerHTML]="privacyPolicy"></div>

    <div class="spacer"></div>

    <div class="contact-hr-box">
        <a (click)="acceptTerms()">{{ 'terms-and-conditions.accept' | translate }}</a>
    </div>

</ion-content>
