import {Component, Input, ElementRef, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-menu-slider',
    templateUrl: './menu-slider.component.html',
    styleUrls: ['./menu-slider.component.scss'],
})
export class MenuSliderComponent {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() withoutPadding: boolean;
    @Input() closeOnClick = true;
    @Input() freeHeight = false;
    @Input() withoutYPadding = false;

    @Output() menuSliderClosed: EventEmitter<any> = new EventEmitter();

    public active = false;

    constructor(
        // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
        public _element: ElementRef
    ) {
    }

    open() {
        this.active = true;
    }

    close() {
        this.active = false;
        this.menuSliderClosed.emit();
    }

    closeOnContentClick() {
        if (!this.closeOnClick) {
            return;
        }

        this.close();
    }
}
