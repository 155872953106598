import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage, IonicStorageModule } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { AppVersion } from '@ionic-native/app-version/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MasterPageModule } from './views/master/master.module';
import { NewsPageModule } from './views/news/news.module';
import { DocumentsPageModule } from './views/documents/documents.module';
import { DocumentsDetailPageModule } from './views/documents-detail/documents-detail.module';
import { NewsDetailPageModule } from './views/news-detail/news-detail.module';
import { AuthorsPageModule } from './views/authors/authors.module';
import { ManualListPageModule } from './views/manual-list/manual-list.module';
import { ManualPageModule } from './views/manual/manual.module';
import { ManualChangesPageModule } from './views/manual-changes/manual-changes.module';
import { RefreshTokenInterceptor } from './interceptors/refresh-token-interceptor';
import { AuthService, authStateKey } from './services/auth/auth.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PushNotificationsPageModule } from './views/push-notifications/push-notifications.module';
import { AppendAppVersionInterceptor } from './interceptors/append-app-version-interceptor';
import { DocumentsSearchModule } from './views/documents-search/documents-search.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FilePreviewModule } from './views/file-preview/file-preview.module';
import { TermsAndConditionsPageModule } from './views/terms-and-conditions/terms-and-conditions.module';
import { PrivacyPolicyPageModule } from './views/privacy-policy/privacy-policy.module';
import { AccodingHandbooksComponent } from './components/accoding-handbooks/accoding-handbooks.component';
import { FavoritePageModule } from './views/news/favorite/favorite.module';
import { WorkClothingPageModule } from './views/profile/work-clothing/work-clothing.module';
import { DocumentNavigationModalComponent } from "./components/document-navigation-modal/document-navigation-modal.component";
import { AttendModalComponent } from "./components/attend-modal/attend-modal.component";
import { AccodeDocumentModalComponent } from "./components/accode-document-modal/accode-document-modal.component";
import { PublicPrivacyPolicyPageModule } from "./views/public/privacy-policy/public-privacy-policy.module";

export function jwtOptionsFactory(storage) {
    return {
        tokenGetter       : async () => {
            const authState = await storage.get(authStateKey);
            return authState && authState.token ? authState.token : null;
        },
        whitelistedDomains: [/.*/]
    };
}

export function authServiceFactory(provider: AuthService) {
    return () => provider.setAuthState();
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, AccodingHandbooksComponent, DocumentNavigationModalComponent, AttendModalComponent, AccodeDocumentModalComponent],
    imports     : [
        BrowserModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true,
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        MasterPageModule,
        NewsPageModule,
        FilePreviewModule,
        DocumentsPageModule,
        DocumentsDetailPageModule,
        DocumentsSearchModule,
        NewsDetailPageModule,
        PdfViewerModule,
        AuthorsPageModule,
        ManualListPageModule,
        ManualPageModule,
        ManualChangesPageModule,
        PushNotificationsPageModule,
        TermsAndConditionsPageModule,
        PrivacyPolicyPageModule,
        PublicPrivacyPolicyPageModule,
        FavoritePageModule,
        WorkClothingPageModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide   : JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps      : [Storage],
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide   : TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps      : [HttpClient]
            }
        }),
    ],
    providers   : [
        AppVersion,
        StatusBar,
        SplashScreen,
        FirebaseX,
        InAppBrowser,
        AuthService,
        { provide: APP_INITIALIZER, useFactory: authServiceFactory, deps: [AuthService], multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppendAppVersionInterceptor, multi: true },
    ],
    bootstrap   : [AppComponent],
    schemas     : [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
