import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { IonSearchbar, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { MenuSliderComponent } from '../../components/menu-slider/menu-slider.component';
import { DocumentsService } from '../../services/documents/documents.service';
import { DocumentModel } from '../../entities/DocumentModel';
import { ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AlertService } from '../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { BrowserService } from '../../services/browser/browser.service';

@Component({
    selector: 'app-documents',
    templateUrl: './documents-detail.page.html',
    styleUrls: ['./documents-detail.page.scss'],
})
export class DocumentsDetailPage implements OnInit {
    @ViewChild('search') search: IonSearchbar;
    @ViewChild('sortStateMenu') sortStateMenu: MenuSliderComponent;

    // Sort menu state
    private menuState = false;

    // Variables for which sort method is selected in the sort menu.
    public sortLastOpened = false;
    public sortLastChanged = false;
    public sortByName = true;
    public noDataInFolder = false;

    public lastOpenedDocument = [];
    public documents = Array<DocumentModel>();
    public isLoading = false;
    private currentPage = 1;
    fetched = new BehaviorSubject(false);


    constructor(private authService: AuthService,
                private documentService: DocumentsService,
                private alertService: AlertService,
                private translate: TranslateService,
                private storage: Storage,
                private route: ActivatedRoute,
                private navController: NavController,
                private browserService: BrowserService) {
        // Pivot
        this.lastOpenedDocument = [];

        // Get the lastOpenedList and make sure the local array corresponds to that.
        this.storage.get('lastOpenedList').then(list => {
            if (list !== null) {
                this.lastOpenedDocument = list;
            }
        });
    }

    /**
     * Show/hide the sort state menu.
     */
    showSortStateMenu() {
        setTimeout(() => {
            this.menuState = !this.menuState;

            if (this.menuState) {
                this.sortStateMenu.open();
            } else {
                this.sortStateMenu.close();
            }
        }, 50);
    }

    /**
     * When a sort method is selected, trigger one of these cases.
     */
    selectSortMethod(id: any) {
        switch (id) {
            // Sort by name
            case(1):
                this.storage.set('filterPreference', 1);
                this.documents.sort((a, b) => a.name.localeCompare(b.name));
                this.sortByName = true;
                this.sortLastOpened = false;
                this.sortLastChanged = false;
                break;

            // Sort by last changed
            case(2):
                this.storage.set('filterPreference', 2);
                // @ts-ignore
                this.documents.sort((a, b) => b.lastUpdate - a.lastUpdate);
                this.sortLastChanged = true;
                this.sortByName = false;
                this.sortLastOpened = false;
                break;

            // Sort by last opened
            case(3):
                this.filterOnLastOpened();
                this.storage.set('filterPreference', 3);
                this.sortLastOpened = true;
                this.sortByName = false;
                this.sortLastChanged = false;
                break;

            // Set the default to name sort.
            default:
                this.documents.sort((a, b) => a.name.localeCompare(b.name));
                this.storage.set('filterPreference', 1);
                this.sortByName = true;
                this.sortLastOpened = false;
                this.sortLastChanged = false;
                break;
        }
    }

    /**
     * Set or update the date in the lastOpenedDocument array of that specific document/folder opened.
     * DocumentModel document
     */
    setLastOpenedDate(document: DocumentModel) {
        this.storage.get('lastOpenedList').then(list => {
            if (list === null) {
                this.storage.set('lastOpenedList', []);
            }
        });

        const lastOpened = this.documents.find(doc => {
            return doc === document;
        });

        // Checks if the clicked item is already in the lastOpenedDocument array.
        if (this.lastOpenedDocument !== null && this.lastOpenedDocument.length > 0) {
            for (const item of this.lastOpenedDocument) {
                const alreadyOpenedDocument = this.getLastOpenedDocumentByFind(lastOpened.id, lastOpened.type);

                // If it is not, then we'll add it to it so that it gets pushed into local storage.
                // Else we'll just update the timestamp.
                if (alreadyOpenedDocument === undefined) {
                    this.lastOpenedDocument.push({id: lastOpened.id, type: lastOpened.type, lastOpened: lastOpened.lastOpened});
                } else if (alreadyOpenedDocument.id === lastOpened.id && alreadyOpenedDocument.type === lastOpened.type) {
                    alreadyOpenedDocument.lastOpened = new Date(Date.now());
                }
            }
        } else {
            // Else we'll directly push the parameters into the array
            this.lastOpenedDocument.push({id: lastOpened.id, type: lastOpened.type, lastOpened: lastOpened.lastOpened});
        }

        this.storage.set('lastOpenedList', this.lastOpenedDocument);
    }

    /**
     * Get a document from the array by ID and Type combined.
     */
    getDocumentByFind(id: number, type: string) {
        return this.documents.find(document => document.id === id && document.type === type);
    }

    /**
     * Get a document from the array by ID and Type combined.
     */
    getLastOpenedDocumentByFind(id: number, type: string) {
        return this.lastOpenedDocument.find(document => document.id === id && document.type === type);
    }

    /**
     * Triggered when case 3 is true.
     * Filter based on lastOpened.
     */
    filterOnLastOpened() {
        for (const item of this.lastOpenedDocument) {
            const document = this.getDocumentByFind(item.id, item.type);
            if (document !== undefined) {
                document.lastOpened = item.lastOpened;
            }
        }

        // @ts-ignore
        this.documents.sort((a, b) => b.lastOpened - a.lastOpened);
    }


    closeSortStateMenu() {
        this.sortStateMenu.close();
    }

    ionViewWillEnter() {
        this.sortStateMenu.close();
        this.currentPage = 1;
        this.storage.get('filterPreference').then((val) => {
            this.selectSortMethod(val);
        });

        this.storage.get('lastOpenedList').then(list => {
            if (list !== null) {
                this.lastOpenedDocument = list;
            }
        });
    }

    /**
     * Refresh the documents.
     */
    refreshDocuments() {
        this.currentPage = 1;
        this.documents = [];
        this.fetchDocuments();
    }

    /**
     * Fetch the documents from the API.
     */
    fetchDocuments() {
        this.isLoading = true;

        this.documentService.fetchSpecificFolder(this.route.snapshot.params.folderId).subscribe(data => {
            // Check if there is no folders or documents.
            if (data.length === 0) {
                this.noDataInFolder = true;
            }

            data.forEach(item => {
                this.documents.push(new DocumentModel(item));
            });
            this.storage.get('filterPreference').then((val) => {
                this.selectSortMethod(val);
            });
            this.fetched.next(true);
            this.currentPage++;
            this.isLoading = false;
        },
            (error) => {
                this.alertService.warning(this.translate.instant('documents.no-access'));
                this.isLoading = false;
            });
    }

    ngOnInit(): void {
        this.documents = [];
        this.fetchDocuments();
    }

    doRefresh(event) {
        setTimeout(() => {
            this.refreshDocuments();
            event.target.complete();
        }, 1000);
    }

    openLink(document: DocumentModel) {
        if (document.type === 'map') {
            this.navController.navigateForward('/documents/' + document.id);
        } else if (document.type === 'doc') {
            if (document.docType === 'document') {
                this.navController.navigateForward('/preview/4/' + document.id, {queryParams: {hasToAccept: document.hasToAccept, isAcceptedByUser: document.isAcceptedByUser}});
            } else if (document.docType === 'url') {
                this.browserService.openLink(document.url);
            }
        }
    }
}
